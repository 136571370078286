import { Card } from "@mui/material"
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { openErrorDetails, updateBugsAtAdminConsole } from "../adminAction";
import { Button } from '@mui/material';
import AlertMessages from '../../Layouts/Alerts/Alert';
import './logs.css'

export const LogDetails = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { log, clientId, clientName } = location.state;

    const [errorDetails, setErrorDetails] = useState(null);
    const [ruleDetail, setRuleDetails] = useState(null);
    const [errComment, setErrComment] = useState('');
    const [comment, setComment] = useState('')
    const [isReviewed, setIsReviewed] = useState(false)
    let token = jwtDecode(sessionStorage.getItem('userToken'));

    const submitComment = () => {
        try {
            if(errComment.length === 0){
                throw new Error('Write something on textbox')
            }
            const reqPayload = {
                id: log.id,
                practiceId: log.practiceId,
                clientId: clientId,
                comment: errComment + ' :- ' +token?.name + ' (' + new Date().toLocaleString() +').'
            }
            dispatch(updateBugsAtAdminConsole( reqPayload)).then((resp) => {
                AlertMessages('Log Updated Successfully', 'Success', 1500)
                if(resp.payload.code === 200){
                    setComment(resp.payload.data[0].comment)
                    setErrComment('')
                }
            }).catch((error) => AlertMessages('Something went wrong', 'Error', 2000))
        } catch (error) {
            AlertMessages(error.message, 'Error', 2000)
        }
    }

    const changeReviewStatus = (event) => {
        try {
            const reqPayload = {
                id: log.id,
                isReviewed : event.target.value,
                practiceId: log.practiceId,
                clientId: clientId
            }
            dispatch(updateBugsAtAdminConsole( reqPayload)).then((resp) => {
                if(resp.payload.code === 200){
                    AlertMessages('Log Updated Successfully', 'Success', 1000)
                    setIsReviewed(resp.payload.data[0].isReviewed)
                } else {
                    AlertMessages('Something went wrong', 'Error', 2000)
                }
            }).catch((error) => AlertMessages(error.message, 'Error', 2000))
        } catch (error) {
            AlertMessages(error.message, 'Error', 2000)
        }
    }

    useEffect(() => {
        const reqPayload = {
            ID : log?.id,
            clientId: clientId,
            PracticeID: log.practiceId,
            EncounterID: log.encounterId,
        }
        dispatch(openErrorDetails(reqPayload)).then((resp) => {
            setErrorDetails(resp.payload.data.patientDetail);
            setRuleDetails(resp.payload.data.ruleDetail);
            setComment(resp.payload.data.comment)
            setIsReviewed(resp.payload.data.isReviewed)
        })
    }, [])
      
     return <section className='client_section'>
        <div style={{}}>
            <div className="row">
                <div className='col-lg-6 col-sm-6'>
                    <div className='clients_col font-face-futura'>
                        <span>View Log</span>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-6 '>
                    <div className='float-right font-face-futura'>
                        <select style={{float: "right", position: "relative", right: "37px", width: "150px"}} value={isReviewed} onChange={(e) => changeReviewStatus(e)} className='filter_drop text-center'>
                            <option value={'false'}>New</option>
                            <option value={'progress'}>In Progress</option>
                            <option value={'forReview'}>For Review</option>
                            <option value={'true'}>Completed</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className='mt-4'>
            <div className='table-responsive font-face-futura' style={{width: '98%'}}>
                <Card variant="outlined"  >
                    <Card variant="" style={{float: 'left', textAlign: 'left', display: 'inline-block', margin: '10'}} sx={{ maxWidth: 345 }} >
                        <CardContent>
                            <Box style={{padding: '30px'}}>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Log Type :  {log.errorType}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Report Date : {((1 + new Date(log.created_at).getMonth()) < 10 ? '0'+(1 + new Date(log.created_at).getMonth()) : 1 + new Date(log.created_at).getMonth() ) +'/'+ ((new Date(log.created_at).getDate()) < 10 ? '0'+(new Date(log.created_at).getDate()) : new Date(log.created_at).getDate()) +'/'+ new Date(log.created_at).getFullYear()}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Report Time : {new Date(log.created_at).getHours() + ':'+ new Date(log.created_at).getMinutes()}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Status : {log.isReviewed ? 'Reviewed' : 'Not Reviewed'}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card variant="" style={{textAlign: 'left', display: 'inline-block', margin: '10'}} sx={{ maxWidth: 345 }} >
                        <CardContent>
                            <Box style={{padding: '30px'}}>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Client Name : {clientName}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Practice Name : {log.companyName}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Report Contact : {log.user}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Report Email : {log.user === 'system' ? 'N/A' : log.first_name + " " + log.last_name }
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    {comment && <div>
                        <h3>Comment</h3>
                        <p>{comment.split('</br>').map((item) => <p>{item}</p> )}</p>
                    </div>}
                    <Card>
                        <textarea placeholder='Type here...' value={errComment} onChange={ e => setErrComment(e.target.value)} style={{
                            width: '100%',
                            height: '130px',
                            margin: '51px 0 25px',
                            background: '#fff',
                            borderRadius: '9px',
                            border: '1px solid #9a9a9a',
                            fontSize: '18px',
                            padding: '0.5rem'
                        }} />
                        <Button onClick={() => setErrComment('')}>Cancel</Button>
                        <Button onClick={submitComment}>Submit</Button>
                        <div style={{
                            margin: '51px 0 25px'
                        }}></div>
                    </Card>
                    {errorDetails ? <>
                        <h2>Patient Details</h2>
                        <table className='table client-table'>  
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>{errorDetails.PatientName} </td>
                                </tr>
                                <tr>
                                    <td>Encounter ID</td>
                                    <td>{errorDetails.EncounterID}</td>
                                </tr>
                                <tr>
                                    <td>Encounter Procedure ID</td>
                                    <td>{errorDetails.EncounterProcedureID} </td>
                                </tr>
                                <tr>
                                    <td>Primary Insurance Plan Name</td>
                                    <td>{errorDetails.PrimaryInsurancePlanName} </td>
                                </tr>
                                <tr>
                                    <td>Primary Insurance Policy Number</td>
                                    <td>{errorDetails.PrimaryInsurancePolicyNumber} </td>
                                </tr>
                                <tr>
                                    <td>Rendering Provider Name</td>
                                    <td>{errorDetails.RenderingProviderName} </td>
                                </tr>
                                <tr>
                                    <td>Scheduling Provider Name</td>
                                    <td>{errorDetails.SchedulingProviderName} </td>
                                </tr>
                                <tr>
                                    <td>Encounter Status</td>
                                    <td>{errorDetails.EncounterStatus} </td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td>{errorDetails.Gender} </td>
                                </tr>
                                <tr>
                                    <td>Patient Date Of Birth</td>
                                    <td>{errorDetails.PatientDateOfBirth} </td>
                                </tr>
                                <tr>
                                    <td>PatientID</td>
                                    <td>{errorDetails.PatientID} </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{errorDetails.AddressLine1 + " " +  errorDetails.AddressLine2}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{errorDetails.City}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{errorDetails.Country}</td>
                                </tr>
                                <tr>
                                    <td>Age</td>
                                    <td>{errorDetails.Age}</td>
                                </tr>
                                <tr>
                                    <td>Alert Message</td>
                                    <td>{errorDetails.AlertMessage}</td>
                                </tr>
                                <tr>
                                    <td>Case Name</td>
                                    <td>{errorDetails.CaseName}</td>
                                </tr>
                                <tr>
                                    <td>PatientID</td>
                                    <td>{errorDetails.PatientID}</td>
                                </tr>
                                <tr>
                                    <td>Created Date</td>
                                    <td>{errorDetails.CreatedDate}</td>
                                </tr>
                                <tr>
                                    <td>Service Location Name</td>
                                    <td>{errorDetails.ServiceLocationName}</td>
                                </tr>
                                <tr>
                                    <td>Place Of Service Code</td>
                                    <td>{errorDetails.ServiceLocationPlaceOfServiceCode}</td>
                                </tr>
                                <tr>
                                    <td>Encounter Diagnosis ID 1</td>
                                    <td>{errorDetails.EncounterDiagnosisID1}</td>
                                </tr>
                                <tr>
                                    <td>Encounter Diagnosis ID 2</td>
                                    <td>{errorDetails.EncounterDiagnosisID2}</td>
                                </tr>
                                <tr>
                                    <td>Encounter Diagnosis ID 3</td>
                                    <td>{errorDetails.EncounterDiagnosisID3}</td>
                                </tr>
                                <tr>
                                    <td>Encounter Diagnosis ID 4</td>
                                    <td>{errorDetails.EncounterDiagnosisID3}</td>
                                </tr>
                                <tr>
                                    <td>Modifier 1</td>
                                    <td>{errorDetails.ProcedureModifier1}</td>
                                </tr>
                                <tr>
                                    <td>Modifier 2</td>
                                    <td>{errorDetails.ProcedureModifier2}</td>
                                </tr>
                                <tr>
                                    <td>Unit</td>
                                    <td>{errorDetails.Units}</td>
                                </tr>
                                <tr>
                                    <td>Unit Charge</td>
                                    <td>{errorDetails.UnitCharge}</td>
                                </tr>
                                <tr>
                                    <td>Total Charges</td>
                                    <td>{errorDetails.TotalCharges}</td>
                                </tr>
                                <tr>
                                    <td>Hospitalization Start Date</td>
                                    <td>{errorDetails.HospitalizationStartDate}</td>
                                </tr>
                                <tr>
                                    <td>Service Start Date</td>
                                    <td>{errorDetails.ServiceStartDate}</td>
                                </tr>
                                <tr>
                                    <td>Procedure Code</td>
                                    <td>{errorDetails.ProcedureCode}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    : <></>}
                    {ruleDetail ? <>
                    <h2>Rule Details</h2>
                    <table className='table client-table'>
                        <thead className='tableHead text-white'>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>{ruleDetail.ruleName} </td>
                            </tr>
                            <tr>
                                <td>Created By</td>
                                <td>{ruleDetail.createdBy}</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{ruleDetail.modified_at}</td>
                            </tr>
                            <tr>
                                <td>Result</td>
                                <td>{ruleDetail.result}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td style={{width: '62%', padding: '8px 35px'}}>
                                    <p>When the {' '}
                                        {
                                            ruleDetail?.ruleInfo?.map((item, idx) => {
                                            let error = "{{error}}";
                                            let procedureTileValue;
                                            let finalValues;
                                            let procedureValues;
                                            
                                            let tiles = ruleDetail?.ruleInfo?.filter(item => item.procedureTile);
                                            

                                            switch (item.category) {
                                                case 'Diagnosis 1':
                                                case 'Diagnosis 2':
                                                case 'Diagnosis 3':
                                                case 'Diagnosis 4':
                                                case 'Diagnosis in any slot':
                                                case 'Modifier':
                                                    procedureTileValue = item.procedureTile === 'any' ? 'for any procedure' : `for procedure tile ${item.procedureTile}`
                                                    break;
                                                default:
                                                    procedureTileValue = ""
                                                    break;
                                            }
                                            switch (item.category) {
                                                case 'Global Period':
                                                    finalValues = (item.values.map(val => val.name ? val.name : val)).join(' or ');
                                                    procedureValues = (item.procedureArray.map(val => val.name ? val.name : val)).join(' or ');
                                                    break;
                                                default:
                                                    break;
                                            }

                                            finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name : val)).join(' or ') : (item.values.map(val => val.name ? val.name : val)).join(' and ');
                                            return (<>
                                                {item.category === 'Global Period' ?
                                                    <>
                                                        <span>{item.category}</span>{' '}
                                                        <span>for the procedure(s)</span>{' '}
                                                        <span style={{ color: '#297200' }}>{finalValues}</span>{' '}
                                                        <span>is within</span>{' '}
                                                        {item.days && (<span style={{ color: '#297200' }}>{item.days}{' '}</span>)}
                                                        <span>{(item.days === '0' || item.days === '1') ? 'day' : 'days'},</span>{' '}
                                                        <span>{item?.globalDrop}</span>{' '}
                                                        <span>we cannot bill the procedure(s)</span>{' '}
                                                        <span style={{ color: '#297200' }}>{procedureValues}{' '}</span>
                                                    </> :
                                                    <>
                                                        <span>{(idx > 0 || tiles.length > 0 ? ' the ' + item.category + ' ' + procedureTileValue : item.category + ' ' + procedureTileValue) + ' ' + item.condition}</span>{' '}
                                                        <span style={{ color: (finalValues.length === 0 && item.condition !== 'is missing') ? '#964D4D' : '#535353' }}>
                                                            {((finalValues && finalValues.length !== 0) || item.condition === 'is missing') ? finalValues : error}
                                                        </span>{' '}
                                                    </>
                                                }
                                                <span>{(item.operator).toLowerCase()}</span>
                                            </>)
                                        })} {ruleDetail?.result}.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                    : <></>}
                    <Card variant="" style={{textAlign: 'left', }} sx={{ maxWidth: '100%' }} >
                        <CardContent>
                            <Box style={{padding: '30px'}}>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {log.errorMessage}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {log.errorDescription}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    
                </Card>
            </div>
        </div>
     </section>
}