import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { manualAutoRun } from '../adminAction';
import { RoCheckbox } from '../../../layouts/RoCheckbox/Checkbox';

const AutoRun = (props) => {
    const dispatch = useDispatch();

    const [clientDetails, setClientDetails] = useState({
        key: '',
        clientName: '',
        practices: []
    });
    const [practices, setPractices] = useState([]);
    const [practiceSearch, setPracticeSearch] = useState([]);
    const [selectedPracticeId, setSelectedPracticeId] = useState([]);
    const [searchKey, setSearchKey] = useState('');

    // function to search practices
    const searchPractice = (value) => {
        let searchResult = [];
        console.log(value);
        setSearchKey(value);
        console.log(practiceSearch);
        searchResult = practiceSearch.filter(item => {
            if (
                item.practiceName.toUpperCase().indexOf(value.toUpperCase()) !== -1
              ) {
                return item;
            }
        });
        console.log(searchResult)
        setPractices(searchResult);
    }

    // function to handle checkbox values
    const handleCheckbox = (e) => {
        console.log(e)
        let value = Number(e.target.value)
        let newArr = [...selectedPracticeId, value];
        if (selectedPracticeId.includes(value)) {
        newArr = newArr.filter((id) => id !== value);
        }
        setSelectedPracticeId(newArr);
    }

    const handleCheckAll = () => {
        if(selectedPracticeId.length < clientDetails?.practices.length){
            let selectedIds = clientDetails?.practices.map(item => item.id);
            setSelectedPracticeId(selectedIds)
        }else {
            setSelectedPracticeId([])
        }
    }

    useEffect(() => {
        setClientDetails({
            key: props?.clientDetails?.id,
            clientName: props?.clientDetails?.client_name,
            practices: props?.clientDetails?.practiceTables
        });
        setPractices(props?.clientDetails?.practiceTables)
        setPracticeSearch(props?.clientDetails?.practiceTables)
    }, [props])

    // function to mark all encounters for a client as reviewed
    const manualAutorun = () => {
        let data = {
            key: clientDetails?.key,
            practiceIds: selectedPracticeId.map(String)
        }
        console.log(data)
        dispatch(manualAutoRun(data)).then((res) => {
            console.log(res);
            props.closeAutoRun();
            setSelectedPracticeId([]);
        })
    }

    // function to close pop up
    const nevermind = () => {
        props.closeAutoRun();
        setSelectedPracticeId([]);
    }
    
    return (
        <>
            {props.openAutoRun && <Dialog fullWidth
                open={props.openAutoRun}
                onClose={props.closeAutoRun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <div className='modalTitle'>
                    <DialogTitle id="alert-dialog-title">
                        <span className='font-face-futura'>{"Manual Auto-Run"}</span>
                    </DialogTitle>
                </div>
                <div className='my-2 font-face-futura' align="center">
                    <span>Completing this action will run auto-run for the following<br />practices. Users will not be able to run encounters for a</span><br />
                    <span>particular practice until auto-run has completed.</span>
                </div>
                <DialogContent className='pos-relative'>
                    <div className='mt-2'>
                        <Card className='modalCard mb-4' style={{height: '130px'}}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Profile</h5>} />
                            <CardContent className='modalContent'>
                                <div className="mx-1 row">
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="clientName"><strong>Client Name:</strong></label>
                                        <input className='inputField disabled' disabled
                                            name="clientName" 
                                            value={clientDetails.clientName}
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="modalCard practices pos-relative" style={{height: '270px'}}>
                            <CardHeader className='modalCardHead'
                                title={<h5 className="my-2 text-white font-face-futura">Select Practice</h5>} />
                            <CardContent className='addGroupContent mx-2 pbb-0'>
                                <div>
                                    <input className='p-2 text modifier-search'
                                        type='text'
                                        value={searchKey}
                                        placeholder='Type something to search...'
                                        onChange={(e) => searchPractice(e.target.value)}
                                        /><br />
                                    <div className='my-2 font-face-futura mark_drop'>
                                        {practices?.length > 0 ? (
                                            practices?.map((option, idx) => (
                                            <div key={idx} className='optionDrop'>
                                                <RoCheckbox
                                                style={{ width: 18, height: 18 }}
                                                value={option.id}
                                                checked={selectedPracticeId?.includes(option.id)}
                                                onChange={handleCheckbox}
                                                />
                                                <span className='spantext'>
                                                    {option.practiceName}
                                                </span>
                                            </div>
                                            ))
                                        ): (
                                            <span className="noRecord">No Result found!</span>
                                        )}
                                        <div className='mx-2 check_all' onClick={handleCheckAll}>
                                            <span>CHECK ALL</span>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <footer className='set-btn'>
                            <div className='actionButton'>
                                <Button fullWidth
                                    className="mt-4 mx-2 nevermindBtn"
                                    variant="outlined" 
                                    onClick={nevermind}
                                    >
                                    <span className="btnSpan">Nevermind</span>
                                </Button>
                                <Button fullWidth
                                    className="mt-4 mx-2 addClientBtn"
                                    variant="outlined"
                                    // disabled={showResult ? false : true}
                                    onClick={manualAutorun}
                                    >
                                    <span className="btnSpan">Start</span>
                                </Button>
                            </div>
                        </footer>
                    </div>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default AutoRun;