import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Card, CardContent, CardHeader, TablePagination } from '@mui/material';
import infoIcon from '../../../assets/icons/info.svg';
import editIcon from '../../../assets/icons/settingEdit.svg';
import openInNew from '../../../assets/icons/openDetail.svg';
import AddClient from './AddClient';
import { getClients } from '../adminAction'
import EditClient from './EditClient';
import { getMasterSettings } from '../../Settings/settingsActions';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const Clients = (props) => {
    const navigate = useNavigate();
    const client = useSelector(state => state.client);
    // console.log(client);
    const dispatch = useDispatch();
    const [addClientPopup, setAddClientPopup] = useState(false);
    const [editClientPopup, setEditClientPopup] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [clientListCopy, setClientListCopy] = useState([]);
    const [invoiceSettings, setInvoiceSettings] = useState({});
    const [clientData, setClientData] = useState({});
    const [filterVal, setFilterVal] = useState('Active');
    const [searchKey, setsearchKey] = useState('client_name');
    const [searchVal, setsearchVal] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [optionSelector, setOptionSelector] = useState(false);

    // pagination constants
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const searchItems = [
        {key: 'client_name', value: 'Client Name'},
        {key: 'client_type', value: 'Client Type'},
    ]

    // function to handle page change
    const handlePageChange = (event, currentPage) => {
      setPage(currentPage);
    };
  
    // function to handle rows per page
    const handleRowsPerPage = (event) => {
      let rowspage = parseInt(event.target.value, 10);
      setRowsPerPage(rowspage);
      setPage(0);
    };

    // function to handle filter value change
    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilterVal(value);
        let filterResult = clientListCopy.filter((item) => {
            let status = item.status === true ? 'Active' : 'Not Active';
            let res = status.toLowerCase() === value.toLowerCase() ? true : false;
            return res;
        })
        console.log(filterResult);
        setClientList(filterResult);
    }

    // logic to search encounters by dropdown selection
    const handleSelectChange = e => {
        // let value = searchItems.filter(item => {
        //     return item.key === e.target.value
        // })
        // if(searchVal !== ''){
            
        // }
        // setsearchKey(value ? value[0].key : 'clientName');
        // setsearchVal('');
        // setOptionSelector(false);
        // setPlaceholder(value[0].value);
        let res =  searchItems.map((val) => {
            if(val.key === e.target.value){
               return setPlaceholder(val.value)
            }
       })
       setOptionSelector(false)
       setsearchKey(e.target.value)
    }

    const handleInputChange = (e) => {
        // this.setState({searchValue: e.target.value, startClaimsLimit: 0, page: 0 }, () => {
        //     this.searchEncounters(this.state.searchKey ? this.state.searchKey : 'clientName', e.target.value);
        // });
        let value =  e.target.value;
        setsearchVal(value);
        let searchResult = clientListCopy.map((val) => {
            return {
                ...val,
                client_type: val.practiceTables.length > 1 ? 'Multi Practice' : 'Single Practice'
            }
        }).filter((item) => {
            if(item[searchKey].toUpperCase().indexOf(value.toUpperCase()) !== -1){
                return item;
            }
        })
        
        let final_result = searchResult.map((type) => {
            return {
                ...type,
                client_type: type.practiceTables.length > 1 ? 'Multi Practice' : 'Single Practice'
            }
        })
        setClientList(final_result);
    }

    const selectHandler = (e) => {
        setOptionSelector(!optionSelector)
    }

    useEffect(() => {
        dispatch(getClients(clientList)).then((res) => {
            let clients = res.payload.data.list;
            let activeClients = clients.filter(x => x.status === true)
            setClientList(activeClients);
            setClientListCopy(clients);
            setFilterVal("Active");
        })
    }, [0])

    // function to handle add client pop up
    const handleClientPopup = () => {
        setAddClientPopup(!addClientPopup);
    }

    // function to handle edit client pop up
    const handleEditClient = (client) => {
        setClientData(client);
        navigate(`/admin-panel/editClient/${client.uniqueId}`, {state: {clientData: client}, getClients: getClientsList})
    }

    const handleLogClientPage = (client) => {
        const {practiceTables} = client
        const practice =  practiceTables.map((item) => {return {id: item.id, name: item.practiceName}});
        navigate(`/logs`, {state: {practices: practice, clientName: client.client_name, clientId: client.id}})
    }

    const getClientsList = () => {
        dispatch(getClients(clientList)).then((res) => {
            let clients = res.payload.data.list;
            let activeClients = clients.filter(x => x.status === true)
            setClientList(activeClients);
            setClientListCopy(clients);
            setFilterVal("Active");
        })
    }

    // function to fetch master invoice settings
    useEffect(() => {
        dispatch(getMasterSettings(invoiceSettings)).then((res) => {
            let settingsRes = res.payload.data.data[0];
            // console.log("settingsRes", settingsRes)
            setInvoiceSettings(settingsRes)
        })
    }, {})

    // function to open client pop up
    const redirectClient = (id, key) => {
        // console.log(key)
        let email = jwtDecode(sessionStorage.getItem('userToken'));
        let x = {
            clientKey: key,
            email: email.email
          }
          let y = JSON.stringify(x);
          let session = Buffer.from(y).toString("base64");

        window.open(`https://reclaim.ruleout.com/?session=${session}`);
    }

    // function to login to ruleout instance
    // const handleLogin = () => {
    //     let data = {
    //         email: sessionStorage.getItem('adminEmail'),
    //         key: sessionStorage.getItem('clientKey'),
    //         password: loginInfo.password
    //     }
    //     console.log(data);
    //     dispatch(login(data)).then((res) => {
    //         console.log(res);
    //         if(res.payload.data.message === 'Success'){
    //             window.location.replace(`http://localhost:3001?session=${res.payload.data.token}`);
    //         }
    //     })
    // }

    return (
        <section className='client_section'>
            <div className='clientflex'>
                <div className='col-lg-6 col-sm-6'>
                    <div className='clients_col font-face-futura'>
                        <span>CLIENTS</span>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-6'>
                    <div className='filter_col font-face-futura'>
                        <span className='mx-3'>Filter By:</span>
                        <select className='filter_drop text-center' name="filter" value={filterVal ? filterVal : 'Active'} onChange={handleFilterChange}>
                            <option value="active">Active</option>
                            <option value="not active">Not Active</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='col-lg-12 col-sm-12'>
                    <div className='search_filter'>
                        <div className='mx-3 setting-filter'>
                            <select 
                            className={optionSelector === true ? 'sticky-selection show-selector' : 'sticky-selection none-selector'} 
                            onChange={handleSelectChange} multiple>
                            {searchItems.map((item, key) => (<>
                                <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                                </>))}
                            </select>
                            <FilterListIcon style={{ margin: '4px' }} onClick={selectHandler} />
                        </div>
                        <div>
                            <input type='text' value={searchVal}
                            className='setting-search font-face-futura' 
                            placeholder={placeholder ? 'Search by' + ' ' + placeholder + '...' : 'Search by Client Name...'}
                            onChange={handleInputChange} />
                        </div>
                        <Button variant='contained' className='mx-3 add_btn' onClick={handleClientPopup}>Add</Button>
                        <AddClient 
                        openClientModal={addClientPopup} 
                        closeClientModal={handleClientPopup} 
                        getClients={getClientsList}
                        invoiceSettings={invoiceSettings} />
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <Card className='client_card'>
                    <CardHeader className='client_card_head' title={<p className='mt-3 card_head_txt'>Clients List</p>} />
                    <CardContent>
                        <div className='table-responsive font-face-futura'>
                            <table className='table client-table'>
                                <thead className='tableHead text-white'>
                                    <tr>
                                        <th>Client ID</th>
                                        <th>Client Name</th>
                                        <th>Client Type</th>
                                        <th># of EIN's</th>
                                        <th># of Providers</th>
                                        <th>Contact Name</th>
                                        <th>Date Joined</th>
                                        <th>Status</th>
                                        <th>Not Review Count</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientList && clientList.length > 0 ? 
                                    (clientList
                                        .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        ).map((client, idx) => (
                                        <tr>
                                            <td>{client.uniqueId}</td>
                                            <td>{client.client_name}</td>
                                            <td>{client.practiceTables.length > 1 ? 'Multi Practice' : 'Single Practice'}</td>
                                            <td>{client.practiceTables.length}</td>
                                            <td>{client.providerCount}</td>
                                            <td>{client.first_name + " " + (client.last_name ? client.last_name : '')}</td>
                                            <td>{1 + new Date(client.created_at).getMonth() +'/'+ new Date(client.created_at).getDate() +'/'+ new Date(client.created_at).getFullYear()}</td>
                                            <td>{client.status === true ? 'Active' : 'Not Active'}</td>
                                            <td>{client.notReviwedCount}</td>
                                            <td>
                                                <img className='iconImg mx-2' src={infoIcon} alt="info" onClick={() => handleLogClientPage(client)} />
                                                <img className='iconImg mx-2' src={editIcon} alt="edit" onClick={() => handleEditClient(client)} />
                                                <img className='iconImg mx-2' src={openInNew} alt="open" onClick={() => redirectClient(client.id, client.client_key)} />
                                            </td>
                                        </tr>
                                    ))) : (
                                        <tr>
                                          <td colSpan={9} className="noRecord text-center">
                                            No records found!
                                          </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={clientList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPage}
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </section>
    )
}