import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, TablePagination } from '@mui/material';
import openInNew from '../../../assets/icons/openDetail.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllbugs, updateBugsAtAdminConsole } from '../adminAction';
import AlertMessages from '../../Layouts/Alerts/Alert';

export const Logs = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [logList , setLogs] = useState([]);
    const [filterVal, setFilterVal] = useState('false');
    const [allLogs, setAllLogs] = useState(null)
    // pagination constants
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const {practices, clientName, clientId} = location.state;
    // function to handle page change
    const handlePageChange = (event, currentPage) => {
      setPage(currentPage);
    };
  
    // function to handle rows per page
    const handleRowsPerPage = (event) => {
      let rowspage = parseInt(event.target.value, 10);
      setRowsPerPage(rowspage);
      setPage(0);
    };

    const navigateToDetailPage = (log) => {
        navigate(`/log-details`, {state: { log: log,  clientId, clientName }} )
    }

    const changePracticeSelect = async (e) => {
        const reqPayload = {
            practiceId : e.target.value,
            clientId: clientId
        }
        setLogs([])
        dispatch(getAllbugs(reqPayload)).then((res) => {
            if(res.payload.code === 200){
                const requestLogs = res.payload.data.map((log) => {
                    return { ...log, companyName: practices.filter((item) => item.id == log.practiceId)[0].name }
                })
                setAllLogs(requestLogs)
                setLogs(requestLogs.filter((log) => log.isReviewed.toString() === filterVal))
            } 
            if(res.payload.code === 204) {
                setLogs([]);
            }
        }).catch((error) => {
            console.log('getAllbugs Error', error)
        })

    }

    const changeReviewStatus = (event, log) => {
        try {
            const reqPayload = {
                id: log.id,
                isReviewed : event.target.value,
                practiceId: log.practiceId,
                clientId: clientId
            }
            dispatch(updateBugsAtAdminConsole( reqPayload)).then((resp) => {
                if(resp.payload.code === 200){
                    AlertMessages('Log Updated Successfully', 'Success', 1500)
                    setLogs(logList.map((item) => {
                        if(item.id === log.id){
                            return {...item, isReviewed: resp.payload.data[0].isReviewed }
                        } else {
                            return item
                        }
                    }))
                } else {
                    AlertMessages('Something went wrong', 'Error', 2000)
                }
            }).catch((error) => AlertMessages(error.message, 'Error', 2000))
        } catch (error) {
            AlertMessages(error.message, 'Error', 2000)
        }
    }

    const handleFilterChange = (e) => {
        const {value} = e.target;
        setLogs(allLogs.filter((log) => log.isReviewed.toString() === value))
        setFilterVal(value);
    }

    useEffect(() => {
        if(practices?.length){
            const reqPayload = {
                practiceId : 'ALL',
                clientId: clientId
            }
            dispatch(getAllbugs(reqPayload)).then((res) => {
                if(res.payload.code === 200){
                    const requestLogs = res.payload.data.map((log) => {
                        return { ...log, companyName: practices.filter((item) => item.id == log.practiceId)[0]?.name }
                    })
                    setAllLogs(requestLogs)
                    setLogs(requestLogs.filter((log) => log.isReviewed === 'false'))
                }
                if(res.payload.code === 204) {
                    setLogs([]);
                }
            }).catch((error) => {
                console.log('getAllbugs Error', error)
            })
        }
    }, [])

    return (
        <section className='client_section'>
            <div className='clientflex'>
                <div className='col-lg-6 col-sm-6'>
                    <div className='clients_col font-face-futura'>
                        <span>LOGS - {clientName}</span>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-6'>
                    <div className='filter_col font-face-futura'>
                        <span className='mx-3'>Filter By:</span>
                        <select className='filter_drop text-center' name="filterByStatus" value={filterVal} onChange={handleFilterChange}>
                            <option value={'false'}>New</option>
                            <option value={'progress'}>In Progress</option>
                            <option value={'forReview'}>For Review</option>
                            <option value={'true'}>Completed</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='col-lg-12 col-sm-6'>
                    <div className='filter_col font-face-futura'>
                        <span className='mx-3'>Practice Name:</span>
                        <select className='filter_drop text-center' onChange={changePracticeSelect} name="filter" style={{width: '300px'}}>
                            <option value={'ALL'}>All</option>
                            {(practices || []).map((practice) => (
                                <option value={practice.id}>{practice.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='table-responsive font-face-futura' style={{width: '98%'}}>
                    <table className='table client-table'>
                        <thead className='tableHead text-white'>
                            <tr>
                                <th>Client ID</th>
                                <th>Practice Name</th>
                                <th>Log Type</th>
                                <th>Report Date</th>
                                <th>Report Time</th>
                                <th>Report Contact</th>
                                <th>Date Joined</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(logList || []).map((log, i) => (
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{log.companyName}</td>
                                    <td>{log.errorType}</td>
                                    
                                    <td>{((1 + new Date(log.created_at).getMonth()) < 10 ? '0'+(1 + new Date(log.created_at).getMonth()) : 1 + new Date(log.created_at).getMonth() ) +'/'+ ((new Date(log.created_at).getDate()) < 10 ? '0'+(new Date(log.created_at).getDate()) : new Date(log.created_at).getDate()) +'/'+ new Date(log.created_at).getFullYear()}</td>
                                    <td>{ new Date(log.created_at).toLocaleTimeString([], {hour: '2-digit',minute: '2-digit'})}</td>
                                    <td>{log.first_name ? log.first_name + ' ' + log.last_name : log.user?.charAt(0)?.toUpperCase() + log.user.slice(1)}</td>
                                    <td>{((1 + new Date(log.created_at).getMonth()) < 10 ? '0'+(1 + new Date(log.created_at).getMonth()) : 1 + new Date(log.created_at).getMonth() ) +'/'+ ((new Date(log.created_at).getDate()) < 10 ? '0'+(new Date(log.created_at).getDate()) : new Date(log.created_at).getDate()) +'/'+ new Date(log.created_at).getFullYear()}</td>
                                    <td>
                                        <select value={log.isReviewed} onChange={(e) => changeReviewStatus(e, log)} className='filter_drop text-center'>
                                            <option value={'false'}>New</option>
                                            <option value={'progress'}>In Progress</option>
                                            <option value={'forReview'}>For Review</option>
                                            <option value={'true'}>Completed</option>
                                        </select>
                                    </td>
                                    <td>
                                        <img className='iconImg mx-2' onClick={() => navigateToDetailPage(log)} src={openInNew} alt="open" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <TablePagination
                    rowsPerPageOptions={[50, 100, 200]}
                    component="div"
                    count={logList?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPage}
                    />
                </div>
            </div>
        </section>
    )
}