import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import logo from '../../../assets/icons/logo.svg';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, userLogin, verifyLoginOTP } from './authAction';
import { useNavigate } from 'react-router-dom';
import AlertMessages from '../../Layouts/Alerts/Alert';
import {decode, encode} from 'base-64';

export function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error } = useSelector((state) => state.auth);
    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [otpLayer, setOtpLayer] = useState(false);
    const [OTP, setOTP] = useState('');
    const [userName, setUserName] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleSubmit = () => {
        if(isChecked && userInfo?.email !== ''){
            localStorage.email = userInfo?.email
            localStorage.password = encode(userInfo?.password)
            localStorage.remember = isChecked
        }else{
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('remember');
        }
        if (isFormValid()) {
            // console.log(userInfo, errors)
            dispatch(userLogin(userInfo)).then((res) => {
                // console.log(res.payload)
                if (res.payload.code === 200) {
                    setOtpLayer(true);
                    setUserName(res.payload.name);
                    // AlertMessages('Logged In Successfully', 'Success', 2000)
                    // navigate('/admin-panel/clients')
                    // sessionStorage.setItem('adminEmail', res.payload.email)
                } else {
                    AlertMessages(res.payload.message, 'Error', 2000);
                    setUserInfo({ email: '', password: '' });
                }
            })
        }
    }

    // function to handle remember me functionality
    const rememberMe = (e) => {
        setIsChecked(e.target.checked);
    }

    useEffect(() => {
        if(localStorage.remember && localStorage.email !== ''){
            setIsChecked(true);
            setUserInfo({
                email: localStorage.email,
                password: decode(localStorage.password)
            })
        }
    }, isChecked)

    // function to verify otp
    const verifyLoginOtp = () => {
        let body_data = {
            email: userInfo.email,
            OTP: OTP
        }
        console.log(body_data);
        dispatch(verifyLoginOTP(body_data)).then((res) => {
            let code = res.payload.data;
            // console.log(code);
            if (code.code === 200) {
                sessionStorage.setItem("userToken", JSON.stringify(code.token));
                AlertMessages('Logged In Successfully', 'Success', 2000)
                navigate('/admin-panel/clients');
            } else {
                AlertMessages(code.message, 'Error', 2000);
            }
        })
    }

    // function to handle input value change
    const handleChange = (e) => {
        const { name, value } = e.target;
        errors[e.target.name] = '';
        setUserInfo({ ...userInfo, [name]: value });
        if(name === 'otp'){
            setOTP(value);
        }
        setErrors(errors);
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }
    const _handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            verifyLoginOtp();
        }
    }

    // function to handle form validation
    const isFormValid = () => {
        let error = {};
        let isValid = true;
        if (!userInfo.email) {
            isValid = false;
            error.email = "*Please enter a valid email";
        }
        if (!userInfo.password) {
            isValid = false;
            error.password = "*Please enter password";
        }
        setErrors(error);
        return isValid;
    }

    // function to handle forgot password and send otp on mail
    const handleForgotPassowrd = () => {
        let body = {
          email: userInfo.email
        };
        // if (this.ifForgotPassFormValid()) {
          dispatch(forgotPassword(body))
            .then((res) => {
              if (res.payload.data.code === 200) {
                navigate('/forgot-password', {state: {email: userInfo.email, first_name: res.payload.data.first_name}})
              } else {
                AlertMessages(res.payload.data.message, 'Error', 2000)
              }
            })
            .catch((error) => {
              return error;
            });
        // }
      };

    const style = {
        textAlign: 'left',
        height: 0,
        lineHeight: '16px'
    }

    return (
        <>
            {otpLayer === true ?
                <section className="page-backGround">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <Card variant="outlined" className="loginCard h100" onKeyDown={_handleOnEnter}>
                            <CardContent>
                                <div className="row customize-dflex">
                                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                                        <img src={logo} alt="logo" width={420} height={155} />
                                    </div>
                                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                                        <CardHeader
                                            title={
                                                <span className="font-face-poppins pageHeadings">
                                                    Let’s Verify Your Account
                                                </span>
                                            }
                                        />
                                        <div>
                                            <span className="">Welcome {userName} !</span>
                                        </div>
                                        <div className="mt-4">
                                            <label htmlFor="otp">
                                                What is the code that was emailed to you?
                                            </label>
                                            <input
                                                className="inputFields"
                                                name="otp"
                                                type="number"
                                                value={OTP}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <p className="text-danger" style={style}>
                                                {errors.otp}
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            <button className="changePswrd" onClick={verifyLoginOtp}>
                                                <span>Verify</span>
                                            </button>
                                        </div>

                                        <div className="termsCondition mt-2">
                                            <p>
                                                By logging in you agree to the
                                                <a
                                                    href="/"
                                                    style={{ textDecoration: "none", color: "#81C3D7" }}
                                                >
                                                    {" "}
                                                    terms{" "}
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href="/"
                                                    style={{ textDecoration: "none", color: "#81C3D7" }}
                                                >
                                                    conditions
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </section>
                :
                <section className='loginSection'>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <Card variant="outlined" className='loginCard h100'
                            onKeyDown={_handleKeyDown}
                        >
                            <CardContent>
                                <div className='row customize-dflex'>
                                    <div className='col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center'>
                                        <img src={logo} alt='logo' width={420} height={155} />
                                        <div className='adminPanel'>Admin Panel</div>
                                    </div>
                                    <div className='col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align'>
                                        <CardHeader title={
                                            <span className='font-face-poppins accountHead'>
                                                Account Registration
                                            </span>} />
                                        <div>
                                            <label htmlFor='email' style={{ fontSize: 16 }}>Email Address</label><br />
                                            <input fullWidth className='p-2 inputStyle'
                                                type="email"
                                                name="email"
                                                value={userInfo.email}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <p className="text-danger" style={style}>{errors.email}</p>
                                        </div>

                                        <div className='mt-2'>
                                            <label htmlFor='password' style={{ fontSize: 16 }}>Password</label><br />
                                            <input fullWidth className='p-2 inputStyle'
                                                type="password"
                                                name="password"
                                                value={userInfo.password}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <p className="text-danger" style={style}>{errors.password}</p>
                                        </div>

                                        <div className='row'>
                                            <div className='col-6 col-sm-6 col-lg-6 d-flex align-items-center justify-content-start'>
                                                <input
                                                    type='checkbox' className='rememberMeCheck'
                                                    checked={isChecked}
                                                    onChange={rememberMe} />
                                                <span className='ms-2 rememberTxt'>Remember Me</span>
                                            </div>
                                            <div className='col-6 col-sm-6 col-lg-6 mt-3' style={{ textAlign: 'right', paddingRight: 32 }}>
                                                <p><a onClick={handleForgotPassowrd} className='forgotTxt'>Forgot Password?</a></p>
                                            </div>
                                        </div>

                                        <div>
                                            <Button className='buttonStyle'
                                                fullWidth
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >Log In</Button>
                                        </div>

                                        <div className='col-12 col-sm-12 col-lg-12'>
                                            <p><span className='termsTxt2'>By logging in you agree to the </span>
                                                <a href="#" className='termsTxt'>terms </a><span className='termsTxt2'>and </span>
                                                <a href="#" className='termsTxt'>conditions</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </section>
            }
        </>
    )
}